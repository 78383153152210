const axios = require('axios');

export const baseURL = 'https://cws.rwgenting.com/bo/bo-api';
console.log("base?", baseURL);
export const fetchAxios = async (ctx, params, authentication = true, startTime = new Date()) => {
  let { url } = params;
  if (!url.startsWith('http:') && !url.startsWith('https:')) {
    url = `${baseURL}${url}`;
  }

  // console.log('ctx?', ctx, url);
  // wait for authcontext loading
  if (authentication) {
    if(! ctx.loaded) {
      throw new Error('Not authenticated yet');
      // if ((new Date()).getTime() - startTime > 1000 * 10) {
      //   console.log('timed out');
      //   return;
      // }
      // return new Promise((resolve) => {
      //   console.log('waiting authctx');
      //   setTimeout(async () => {
      //     resolve(await fetchAxios(ctx, params, authentication, startTime));
      //   }, 100);
      // })
    }
  }
  const token = ctx._token;
  const p = authentication ? ({ headers: { Authorization: `Bearer ${token}`}, ...params, url, timeout: 120000 }) : ({ ...params, url });
  // console.log('fetch', p.url, p.headers);
  console.log('axios', p);
  return axios(p).then((resp) => resp).catch((err) => {
    const { response } = err;
    console.log('error?', response);
    if (response && response.data) {
      const { data } = response;
      console.error('err', data);

      if (data.message) {
        if (data.message.indexOf('Duplicated record') >= 0 || data.message.indexOf('Record exist') >= 0) {
          throw  new Error('Warning: Record EXIST in the Backend database');
        }
        throw  new Error(data.message);
      }
      if (data.msg) {
        if (data.msg.indexOf('Duplicated record') >= 0 || data.msg.indexOf('Record exist') >= 0) {
          throw  new Error('Warning: Record EXIST in the Backend database.');
        }
        throw new Error(data.msg);
      }
      throw new Error(data.error);
    }
  });


  return axios(p);
}

export const generateQuerySlice = (query, prefix = '', depth = 0) => {
  let list = [];
  Object.keys(query).forEach((key) => {
    const val = query[key];
    if (val === null) {
      //skip
      return;
    }
    if (Array.isArray(val)) {
      val.forEach((s) => {
        list.push([prefix + '[' + key + '][]', s]);
      });
      return;
    }
    if (typeof val === 'object') {
      const childList = generateQuerySlice(val, prefix + '[' + key + ']', depth + 1);
      list = list.concat(childList);
      return;
    }
    if (prefix) {
      list.push([prefix + `[${key}]`, val]);
    } else {
      list.push([prefix + key, val]);
    }

    // list.push(`${prefix}${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
  });

  return list;
}

export const generateQuery = (query, prefix = '') => {
  const list = generateQuerySlice(query, prefix);
  return list.map((row) => `${encodeURIComponent(prefix + row[0])}=${encodeURIComponent(row[1])}`).join('&');
}
