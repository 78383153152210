/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Suspense } from "react";
import { baseURL } from "shared/net";
import Dashboard from "views/Dashboard.js";
import Login from "views/pages/Login.js";
import demoRoutes from './demoroute';

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "nc-icon nc-bank",
    component: Dashboard,
    layout: "/admin",
    menu: false
  },
  {
    collapse: true,
    name: "Master Data",
    icon: "nc-icon nc-book-bookmark",
    state: "masterDataCollapse",
    views: [
      {
        path: "/areas",
        exact: true,
        name: "Area",
        mini: "A",
        component: React.lazy(() => import('pages/Areas/Index')),
        layout: "/admin",
      },
      {
        path: "/positions",
        exact: true,
        name: "Position",
        mini: "T",
        component: React.lazy(() => import('pages/Positions/Index')),
        layout: "/admin",
      },
      {
        path: "/skillsets",
        name: "Skill Set Setup",
        exact: true,
        mini: "SS",
        component: React.lazy(() => import('pages/SkillSets/Index')),
        layout: "/admin",
      },
      {
        path: "/skillgroups",
        name: "Skill Set Group Setup",
        exact: true,
        mini: "SG",
        component: React.lazy(() => import('pages/SkillGroups/Index')),
        layout: "/admin",
      },
      {
        path: "/refresher",
        name: "Refresher Training",
        exact: true,
        mini: "R",
        component: React.lazy(() => import('pages/Refresher/Index')),
        layout: "/admin",
      },
      {
        path: "/shifts",
        name: "Shift",
        exact: true,
        mini: "S",
        component: React.lazy(() => import('pages/Shifts/Index')),
        layout: "/admin",
      },
      {
        path: "/salaryranges",
        name: "Salary Range",
        exact: true,
        mini: "SR",
        component: React.lazy(() => import('pages/SalaryRanges/Index')),
        layout: "/admin",
      },
      {
        path: "/publicholidays",
        name: "Public Holiday",
        exact: true,
        mini: "PH",
        component: React.lazy(() => import('pages/PublicHolidays/Index')),
        layout: "/admin",
      },
      {
        path: "/ratings",
        name: "Rating",
        exact: true,
        mini: "RT",
        component: React.lazy(() => import('pages/Ratings/Index')),
        layout: "/admin",
      },
      {
        path: "/remarks",
        name: "Remark",
        exact: true,
        mini: "R",
        component: React.lazy(() => import('pages/Remarks/Index')),
        layout: "/admin",
      },
      {
        path: "/emailcontents",
        name: "Email Content",
        exact: true,
        mini: "E",
        component: React.lazy(() => import('pages/EmailContent/Index')),
        layout: "/admin",
      },
      {
        path: "/remarks/new",
        exact: true,
        name: "Add Remarks",
        mini: "RA",
        component: React.lazy(() => import('pages/Remarks/New')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/remarks/:id",
        name: "Edit Remarks",
        exact: true,
        mini: "RE",
        component: React.lazy(() => import('pages/Remarks/Edit')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/skillsets/new",
        exact: true,
        name: "Add Skill Sets",
        mini: "SSA",
        component: React.lazy(() => import('pages/SkillSets/New')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/skillsets/:id",
        name: "Edit Skill Sets",
        exact: true,
        mini: "SSE",
        component: React.lazy(() => import('pages/SkillSets/Edit')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/skillgroups/new",
        name: "New Skill Set Group Setup",
        exact: true,
        mini: "SG",
        menu: false,
        component: React.lazy(() => import('pages/SkillGroups/New')),
        layout: "/admin",
      },
      {
        path: "/skillgroups/:id",
        name: "Edit Skill Set Group Setup",
        exact: true,
        mini: "SSG",
        component: React.lazy(() => import('pages/SkillGroups/Edit')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/salaryranges/new",
        exact: true,
        name: "Add Salary Ranges",
        mini: "SRA",
        component: React.lazy(() => import('pages/SalaryRanges/New')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/salaryranges/:id",
        name: "Edit Salary Ranges",
        exact: true,
        mini: "SRE",
        component: React.lazy(() => import('pages/SalaryRanges/Edit')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/shifts/new",
        exact: true,
        name: "Add Shifts",
        mini: "SA",
        component: React.lazy(() => import('pages/Shifts/New')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/shifts/:id",
        name: "Edit Shifts",
        exact: true,
        mini: "SE",
        component: React.lazy(() => import('pages/Shifts/Edit')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/refresher/new",
        exact: true,
        name: "Add Refresher",
        mini: "RA",
        component: React.lazy(() => import('pages/Refresher/New')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/refresher/:id",
        name: "Edit Refresher",
        exact: true,
        mini: "RE",
        component: React.lazy(() => import('pages/Refresher/Edit')),
        layout: "/admin",
        menu: false
      },
      // {
      //   path: "/emailcontents/new",
      //   exact: true,
      //   name: "Add Email Content",
      //   mini: "EA",
      //   component: React.lazy(() => import('pages/EmailContent/New')),
      //   layout: "/admin",
      // },
      {
        path: "/emailcontents/compose",
        exact: true,
        name: "Compose public announcement",
        mini: "EC",
        component: React.lazy(() => import('pages/EmailContent/Compose')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/emailcontents/:id",
        name: "Edit Email Content",
        exact: true,
        mini: "EE",
        menu: false,
        component: React.lazy(() => import('pages/EmailContent/Edit')),
        layout: "/admin",
      },
      
      {
        path: "/events/new",
        exact: true,
        name: "Add New Event",
        mini: "T",
        component: React.lazy(() => import('pages/JobEventCalendar/New')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/events/:id",
        exact: true,
        name: "Edit An event",
        mini: "T",
        component: React.lazy(() => import('pages/JobEventCalendar/Edit')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/profile/:id",
        exact: true,
        name: "PersonalProfile",
        mini: "T",
        component: React.lazy(() => import('pages/ProfileSearch/Edit')),
        layout: "/admin",
        menu: false,
      },
      {
        path: "/positions/new",
        exact: true,
        name: "Add New Position",
        mini: "T",
        component: React.lazy(() => import('pages/Positions/New')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/positions/:id",
        exact: true,
        name: "Edit A position",
        mini: "T",
        component: React.lazy(() => import('pages/Positions/Edit')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/areas",
        exact: true,
        name: "Areas",
        mini: "T",
        component: React.lazy(() => import('pages/Areas/Index')),
        layout: "/admin",
        menu: false
      },
    
      {
        path: "/areas/new",
        exact: true,
        name: "Add Area",
        mini: "T",
        component: React.lazy(() => import('pages/Areas/New')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/areas/:id",
        exact: true,
        name: "Edit Remarks",
        mini: "T",
        component: React.lazy(() => import('pages/Areas/Edit')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/ratings/:id",
        name: "Edit Ratings",
        exact: true,
        mini: "RTE",
        component: React.lazy(() => import('pages/Ratings/Edit')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/publicholidays/new",
        exact: true,
        name: "Add Public Holidays",
        mini: "PHA",
        component: React.lazy(() => import('pages/PublicHolidays/New')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/publicholidays/:id",
        name: "Edit Public Holidays",
        exact: true,
        mini: "PHE",
        component: React.lazy(() => import('pages/PublicHolidays/Edit')),
        layout: "/admin",
        menu: false
      },
    ],
  },
  {
    collapse: true,
    name: "Transactions",
    icon: "nc-icon nc-briefcase-24",
    state: "transactionsCollapse",
    views: [
      {
        path: "/transaction",
        name: "Part-Timer Applications",
        exact: true,
        mini: "CA",
        component: React.lazy(() => import('pages/Transaction/Index')),
        layout: "/admin",
      },
      {
        path: "/events",
        exact: true,
        name: "Job Event Calendar",
        mini: "T",
        component: React.lazy(() => import('pages/JobEventCalendar/Index')),
        layout: "/admin",
      },
      {
        path: "/BlockEmployee",
        name: "Block/Unblock Part-Timer",
        exact: true,
        mini: "BE",
        component: React.lazy(() => import('pages/BlockEmployee/Index')),
        layout: "/admin",
      },
      {
        path: "/profilesearch",
        exact: true,
        name: "Profile Search",
        mini: "T",
        component: React.lazy(() => import('pages/ProfileSearch/Index')),
        layout: "/admin",
      },
      {
        path: "/transaction/new",
        exact: true,
        name: "Add CA Transacition",
        mini: "CAA",
        component: React.lazy(() => import('pages/Transaction/New')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/transaction/:id",
        name: "Edit CA Transacition",
        exact: true,
        mini: "CAE",
        component: React.lazy(() => import('pages/Transaction/Edit')),
        layout: "/admin",
        menu: false
      },
    ],
  },
  {
    collapse: true,
    name: "Reports",
    icon: "nc-icon nc-single-copy-04",
    state: "reportsCollapse",
    views: [      
      {
        path: "/part-timer-listing",
        name: "Part-Timer Listing",
        exact: true,
        mini: "PTL",
        component: React.lazy(() => import('pages/PartTimerListing/Index')),
        layout: "/admin",
      },
      {
        path: "/approved-part-timer",
        name: "Approved Part-Timer",
        exact: true,
        mini: "APT",
        component: React.lazy(() => import('pages/ApprovedPartTimer/Index')),
        layout: "/admin",
      },
      {
        path: "/aapproved-part-timer-work-date",
        name: "Approved Part-Timer (With Work Date)",
        exact: true,
        mini: "APTW",
        component: React.lazy(() => import('pages/ApprovedPartTimerByWorkDate/Index')),
        layout: "/admin",
      },
      {
        path: "/summary-part-time-headcount",
        name: "Summary of Part-Timer",
        exact: true,
        mini: "SPTH",
        component: React.lazy(() => import('pages/SummaryOfPartTimeHeadCount/Index')),
        layout: "/admin",
      },
      {
        path: "/part-time-registration-listing",
        name: "Part-Timer Registration",
        exact: true,
        mini: "PTRL",
        component: React.lazy(() => import('pages/PartTimeRegistrationListing/Index')),
        layout: "/admin",
      },
      {
        path: "/detail-part-timer-listing",
        name: "Part-Timer Rating (Detail Listing)",
        exact: true,
        mini: "DPTL",
        component: React.lazy(() => import('pages/DetailPartTimerListing/Index')),
        layout: "/admin",
      },
      {
        path: "/summary-part-timer",
        name: "Part-Timer Rating (Summary Listing)",
        exact: true,
        mini: "SLPT",
        component: React.lazy(() => import('pages/SummaryListingOfPartTimersRateDepartment/Index')),
        layout: "/admin",
      }
    ],
  },
  {
    collapse: true,
    name: "Access control",
    icon: "nc-icon nc-circle-10",
    state: "accessCollapse",
    views: [      
      {
        path: "/user",
        name: "User",
        exact: true,
        mini: "U",
        component: React.lazy(() => import('pages/User/Index')),
        layout: "/admin",
      },
      {
        path: "/user/new",
        exact: true,
        name: "Add User",
        mini: "UA",
        component: React.lazy(() => import('pages/User/New')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/user/:id",
        name: "Edit User",
        exact: true,
        mini: "UE",
        component: React.lazy(() => import('pages/User/Edit')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/group",
        name: "Group",
        exact: true,
        mini: "U",
        component: React.lazy(() => import('pages/Group/Index')),
        layout: "/admin",
      },
      {
        path: "/group/new",
        exact: true,
        name: "Add Group",
        mini: "UA",
        component: React.lazy(() => import('pages/Group/New')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/group/:id",
        name: "Edit Group",
        exact: true,
        mini: "UE",
        component: React.lazy(() => import('pages/Group/Edit')),
        layout: "/admin",
        menu: false
      },

      {
        path: "/role",
        name: "Role",
        exact: true,
        mini: "U",
        component: React.lazy(() => import('pages/Role/Index')),
        layout: "/admin",
      },
      {
        path: "/role/new",
        exact: true,
        name: "Add Role",
        mini: "UA",
        component: React.lazy(() => import('pages/Role/New')),
        layout: "/admin",
        menu: false
      },
      {
        path: "/role/:id",
        name: "Edit Role",
        exact: true,
        mini: "UE",
        component: React.lazy(() => import('pages/Role/Edit')),
        layout: "/admin",
        menu: false
      },
    ]
  },
  {
    path: "/login",
    name: "Login",
    mini: "L",
    component: Login,
    layout: "/auth",
    menu: false
  },
  {
    name: "Help",
    icon: "fa fa-question-circle-o",
    opentab: `${baseURL}/public/RWG Recruiters Mobile App & Web User Guide.pdf`
  },
  {
    path: "/logout",
    name: "Logout",
    icon: "nc-icon nc-user-run",
    component: React.lazy(() => import('pages/SignOut')),
    layout: "/admin",
  },

];


export default routes.concat(document.location.href.startsWith('http://localhost') ? []: []);
