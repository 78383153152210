import React, { useContext } from 'react';
import { formatDate } from 'shared/date';
import { AuthContextProvider, AuthContext } from "context/AuthContext";

const withCRUD = (CustomComponent, outerProp={}) => {
  // console.log('comp?', component);
  const CrudComp = (props) => {
    const ctx = useContext(AuthContext);
    //return component({formatDate: formatDate, authCtx:ctx, ...outerProp})
    // return <>cool</>;
    return <CustomComponent formatDate={formatDate} authCtx={ctx} {...outerProp} {...props}/>
  };
  return CrudComp;
}

export { withCRUD };
