import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { fetchAxios } from "shared/net";

export const AuthContext = createContext();
const defaultState = {
  isAuthenticated: false,
  id: '',
  username: '',
  displayName: '',
  avatar: '',
  email: '',
  _token: ''
}

class AuthContextProviderClass extends Component {
  constructor(props, context) {
    super(props);
    const ctx = this;
    this.state = {
      loaded: true,
      setUserName (username) {
        ctx.setState({ username });
        localStorage.setItem("_userName", username);
      },
      setEmail: (email) => {
        ctx.setState({ email });
      },
      setAvatar: (avatar) => {
        ctx.setState({ avatar });
      },
      setDisplayName: (displayName) => {
        ctx.setState({ displayName });
      },
      setSignInToken: ctx.setSignInToken,
      loadUser: ctx.loadUser,
      reload: ctx.reload,
      ...defaultState,
      username: localStorage.getItem("_userName"),
      _token: localStorage.getItem("_token"),
      _tokenTime: localStorage.getItem("_tokenTime"),
      _provider: ctx,
      signOut: ctx.signOut,
    };
  }// constructor

  loadUser() {
    const ctx = this._provider ? this._provider : this;
    
    const token = localStorage.getItem("_token");
    const tokenTime = localStorage.getItem("_tokenTime");
    const userName = localStorage.getItem("_userName");
    // console.log('loaduser', token, tokenTime, userName);

    ctx.setState({
      _token: token,
      _tokenTime: tokenTime,
      loaded: true,
      username: userName,
    });
  }

  async reload() {
    const ctx = this._provider ? this._provider : this;
    try {
      await ctx.loadUser();

    } catch(err) {
      console.error('reload error', err);
    }
  }

  // async signUp(creds) {
  //   const ctx = this._provider ? this._provider : this;
    
  // }

  // async confirmUser(code) {
  //   const { id, displayName } = this.state;
  // }

  // async signIn(creds) {
  //   const { email, password } = creds;
  //   //TODO
  // }

  async setSignInToken(token, time = new Date()) {
    const ctx = this._provider ? this._provider : this;
    ctx.setState({
      _token: token,
      _tokenTime: time,
    });
    localStorage.setItem("_token", token);
    localStorage.setItem("_tokenTime", time);
  }

  async signOut() {
    try {
      const ctx = this._provider ? this._provider : this;
      if (ctx.state._token && ctx.state._token !== 'null') {
        // console.log('token?', ctx.state._token);
        await fetchAxios(ctx.state, { url: '/v1/auth/logout'}).then((resp) => {
          console.log('logged out?', resp);
          ctx.setState({ ...defaultState });
        });
      } else {
        console.log('already loggedout');
      }
      
      localStorage.setItem("_token", '');
      localStorage.setItem("_tokenTime", '');
      localStorage.setItem("_userName", '');
    } catch (err) {
      console.log('error signing out:', err);
    }
  }

  render() {
    const { children } = this.props;
    return (
      <AuthContext.Provider value={{
        ...this.state, signUp: this.signUp,
      }}
      >
        {children}
      </AuthContext.Provider>
    );
  }
}

AuthContextProviderClass.defaultProps = {
  children: null,
}

AuthContextProviderClass.propTypes = {
  children: PropTypes.element,
}

export const AuthContextProvider = withRouter(AuthContextProviderClass);
