
const dateFormat = 'DD/MM/YYYY';
const dateTimeFormat = 'DD/MM/YYYY HH:mm:ss';

import moment from  "moment";

export const formatDate = (date, format = 'DD/MM/YYYY HH:mm:ss', offset = 'default') => {
  // debugger
  if (date === null || date === undefined || date === '') {
    return null;
  }
  if (format == 'DD/MM/YYYY' && offset == 'default') {
    return moment(date).format(format);
  }
  const offsetMinutes = moment(date).utcOffset()
  return moment(date).subtract(offsetMinutes, 'minutes').format(format);
}

export {
  dateTimeFormat,
  dateFormat
} 

