/* eslint-disable react/no-string-refs */
/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { NavLink, Redirect } from "react-router-dom";
import { Nav, Collapse, Button } from "reactstrap";
// javascript plugin used to create scrollbars on windows
import avatar from "assets/img/faces/ayo-ogunseinde-2.jpg";
import logo from "assets/img/rwgWhite.png";
import { AuthContext, AuthContextProvider } from 'context/AuthContext';

var ps;

class Sidebar extends React.Component {
  allCollapseStateNames = []
  
  constructor(props) {    
    super(props);
    this.state = this.getCollapseStates(props.routes);
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates(routes) {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks(routes) {
    // console.log(`Sidebar routes`, routes)
    let mappedRoutes = routes.map((prop, key) => {
      // console.log(`Sidebar prop`, prop)
      if (prop.redirect) {
        return null;
      }
      if (prop.menu === false) {
        return null;
      }
      if (prop.collapse) {
        // console.log(`Sidebar prop state`, prop.state)
        var st = {};
        st[prop["state"]] = !this.state[prop.state];

        let collapseState = prop.state
        if (!this.allCollapseStateNames.includes(collapseState)) {
          this.allCollapseStateNames.push(collapseState)
        }
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                // console.log(`Sidebar st`, st)
                for (const collapseStateName of this.allCollapseStateNames) {
                  let collapseState = {}
                  collapseState[collapseStateName] = false
                  this.setState(collapseState)
                }
                this.setState(st)
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} style={{color: "grey"}} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      if (prop.opentab) {
        return (
          <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
            <a href={prop.opentab} target="_blank" rel="noreferrer">
              <i className={prop.icon} style={{color: 'grey'}} />
              <p>{prop.name}</p>
            </a>
          </li>
        )
      }

      if(prop.isLogout) {
        return (
          <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
            <NavLink to={prop.layout + prop.path} activeClassName="" onClick={() => localStorage.clear()}>
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>{prop.name}</p>
                </>
              ) : (
                <>
                  <span className="sidebar-normal">{prop.name}</span>
                </>
              )}
            </NavLink>
          </li>
        );
      } else {
        if (!prop.name) return null // Don't display sidebar if there is no name
        return (
          <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
            <NavLink to={prop.layout + prop.path} activeClassName="">
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} style={{color: 'grey'}} />
                  <p>{prop.name}</p>
                </>
              ) : (
                <>
                  <span className="sidebar-normal" style={{ whiteSpace: "break-spaces" }}>{prop.name}</span>
                </>
              )}
            </NavLink>
          </li>
        );
      }
      
    });
    console.log('Sidebar createLinks mappedRoutes', mappedRoutes)
    return mappedRoutes
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
  }
  componentWillUnmount() {
  }
  render() {
    return (
      <AuthContext.Consumer>
        {(authValue) => {
          // this.authCtx = authValue;

          // if (!authValue.username) {
          //   return <Redirect to="/auth/login" />
          // }
          return (
            <div
              className="sidebar"
              data-color={this.props.bgColor}
              data-active-color={this.props.activeColor}
            >
              <div className="sidebar-wrapper" ref={(r) => this.sidebar = r}>
                {/* <div className="user">

                  
                </div> */}
                <div className="info" 
                >
                    <img src={logo}/>
                  </div>
                  <div style={{width: "100%", padding: "16px", paddingBottom: 0, wordWrap: "break-word", fontSize: '17px', textTransform: 'uppercase' }}> <strong>Welcome, {  this.props.authCtx.username } </strong> </div>
                <Nav>{this.createLinks(this.props.routes)}</Nav>
              </div>
            </div>)
        }}
      </AuthContext.Consumer>
    );
  }
}

export default Sidebar;
