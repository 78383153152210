/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { fetchAxios } from 'shared/net';
import { AuthContext, AuthContextProvider } from 'context/AuthContext';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
  Alert,
  Spinner,
  Modal,
  ModalBody
} from "reactstrap";

class Login extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      remember: false,
      error: null,
      notice: null,
      loading: false,
      toggleModal: false,
      toggleErrorRequired: false,
      errorMessage: ""
    };
  }
  componentDidMount() {
    document.body.classList.toggle("login-page");
  }
  componentWillUnmount() {
    document.body.classList.toggle("login-page");
  }

  async login() {
    await this.setState({ error: null, notice: null });
    const errors = [];
    // if (this.state.username.length == 0) {
    //   errors.push('Username is required');
    // }
    // if (this.state.password.length == 0) {
    //   errors.push('Password is required');
    // }
    if (this.state.username.length == 0 || this.state.password.length == 0) {
      this.setState({ toggleErrorRequired: !this.state.toggleErrorRequired });
      return;
    }

    if (this.state.username.length > 20) {
      errors.push('Username exceeded 20 charactors');
    }
    if (this.state.password.length > 200) {
      errors.push('Password exceeded 200 charactors');
    }

    if (errors.length > 0) {
      this.setState({ error: errors.join(', '), loading: false });
      return;
    }
    await this.setState({ loading: true });
    const ctx = {};
    
    // console.log('props??', this.props);
    fetchAxios(ctx, { method: 'POST', url: '/v1/auth/login', data: { username: this.state.username, password: this.state.password } }, false).then(async(resp) => {
        // console.log('logged in!', resp);
        // console.log('auth', this.authCtx);
        const { data } = resp;
        if (data.success) {
          await this.authCtx.setSignInToken(data.token);
          await this.authCtx.setUserName(data.username);

          this.props.history.push('/admin/dashboard');
        } else {
          this.setState({ error: data.message, loading: false });
        }
      }).catch((err) => {
        const { response } = err;
        const { data } = response || {};
        const { message } = data || {};

        this.setState({ toggleErrorRequired: !this.state.toggleErrorRequired, loading: false, errorMessage: err.message});
        // this.setState({ error: message || err.message, loading: false });
      });
  }
  render() {
    // const { state } = this.context;

    return (
      <AuthContextProvider>
        <AuthContext.Consumer>
          {(authValue) => {
            this.authCtx = authValue;
            // console.log('authContext', authValue);
            return (
              <div className="login-page">
                <Container>

                  <Row>

                    <Col className="ml-auto mr-auto" lg="5" md="6">
                      <Form action="" className="form" method="">
                        <Card className="card-login">
                          <CardHeader>
                            <CardHeader>
                              <h3
                                className="header text-center"
                                style={{ color: "#0EACBF", marginBottom: 0 }}
                              >
                                RWG RECRUITERS
                              </h3>
                              <h5 className="text-center">PART-TIME JOB APPLICATION</h5>
                              {this.state.error && (
                                <Alert color="danger">{this.state.error}</Alert>
                              )}
                              {this.state.notice && (
                                <Alert color="success">
                                  {this.state.notice}
                                </Alert>
                              )}
                            </CardHeader>
                          </CardHeader>
                          <CardBody style={{ paddingBottom: 0 }}>
                            {/* <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="nc-icon nc-single-02" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder="Username ID" type="text"
                                value={this.state.username}
                                onChange={(e) => this.setState({ username: e.target.value })} />
                            </InputGroup>
                            <InputGroup>
                              <InputGroupAddon addonType="prepend">
                                <InputGroupText>
                                  <i className="nc-icon nc-key-25" />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input
                                placeholder="Password"
                                type="password"
                                autoComplete="off"
                                value={this.state.passsword}
                                onChange={(e) => this.setState({ password: e.target.value })}
                              />
                            </InputGroup> */}
                            <Input placeholder="DOMAIN\ID (executive) or ID (staff)" className="id-input" type="text"
                                value={this.state.username}
                                style={{ padding: "12px", marginBottom: "8px" }}
                                onChange={(e) => this.setState({ username: e.target.value })} />
                            <Input
                                placeholder="Password"
                                type="password"
                                autoComplete="off"
                                value={this.state.passsword}
                                style={{ padding: "12px", marginBottom: "8px" }}
                                onChange={(e) => this.setState({ password: e.target.value })}
                              />
                            <div style={{ textAlign: "right" }}>
                              <a
                                style={{
                                  color: "grey",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                }}
                                onClick={() =>
                                  this.setState({
                                    toggleModal: !this.state.toggleModal,
                                  })
                                }
                              >
                                Help
                              </a>
                            </div>
                            {/* <FormGroup>
                      <FormGroup check>
                        <Label check>
                          <Input
                            checked={this.state.remember}
                            onChange={(e) => this.setState({ remember: e.target.checked })}
                            type="checkbox"
                          />
                          <span className="form-check-sign" />
                          Remember me
                        </Label>
                      </FormGroup>
                    </FormGroup> */}
                          </CardBody>
                          <CardFooter>
                            <Button
                              block
                              className="btn mb-3"
                              // color="primary"
                              style={{ backgroundColor: "#0EACBF" }}
                              onClick={(e) => this.login()}
                              disabled={this.state.loading}
                            >
                              
                              Login
                            </Button>
                            <div className="row flex">{this.state.loading && <Spinner className="center" style={{
                                height: "1rem",
                                width: "1rem"
                              }} />}</div>
                            {/* <p>
                              Please submit eSAR form to reset your password.
                              <br />
                              Helpdesk contact info (internal &amp; external).
                            </p> */}
                            <div style={{textAlign: 'center'}}>
                              &copy; {1900 + new Date().getYear()} Genting Malaysia Berhad (198001004236).
                            </div>
                            <div style={{textAlign: 'center'}}>
                              All Rights Reserved.
                            </div>
                          </CardFooter>
                        </Card>
                      </Form>
                    </Col>
                  </Row>
                  <Modal isOpen={this.state.toggleModal} size="md">
                    <ModalBody>
                      <div className="container-fluid" style={{ padding: 0 }}>
                        <div className="row justify-content-end">
                          <button
                            onClick={() => this.setState({ toggleModal: !this.state.toggleModal })}
                            style={{outline: "none"}}
                            type="button"
                            className="close"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>{" "}
                        </div>
                        <div className="row d-flex flex-column text-center align-items-center">
                          <div style={{backgroundImage: `url("https://img.icons8.com/fluent/48/000000/light-on.png")`, height: "80px", width: "80px", backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}></div>
                          <p style={{ color: "gray", fontSize: "11pt", marginTop: "16px" }}>Please submit eSAR form to reset your password.</p>
                          <p style={{ fontSize: "11pt" }}>HelpDesk:</p>
                          <a style={{ color: "gray", fontSize: "11pt" }} href="https://service.genting.com">https://service.genting.com</a>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>
                  <Modal isOpen={this.state.toggleErrorRequired} size="md">
                    <ModalBody>
                      <div className="container-fluid" style={{ padding: 0 }}>
                        <div className="row justify-content-end">
                          <button
                            onClick={() => this.setState({ toggleErrorRequired: !this.state.toggleErrorRequired })}
                            style={{outline: "none"}}
                            type="button"
                            className="close"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>{" "}
                        </div>
                        <div className="row d-flex flex-column">
                          <p><strong>Note:</strong></p>
                          {/* <p>You&apos;ve entered an invalid ID or password.</p> */}
                          <p>{this.state.errorMessage}</p>
                        </div>
                        <div className="row d-flex flex-row justify-content-end">
                          <button className="btn bg-white text-dark" type="button" onClick={() => this.setState({ toggleErrorRequired: !this.state.toggleErrorRequired })}>Ok</button>
                        </div>
                      </div>
                    </ModalBody>
                  </Modal>
                </Container>
                
              </div>
            )
          }}
        </AuthContext.Consumer>
      </AuthContextProvider>
    );
  }
}

export default Login;
