/* eslint-disable react/no-string-refs */

import React, { Suspense } from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";
import routes from "routes.js";
import { AuthContextProvider, AuthContext } from "context/AuthContext";
import { GlobalContext } from "context/GlobalContext";

import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { withCRUD } from 'shared/default';

import {
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { isExpressionWithTypeArguments } from "typescript";
import { fetchAxios } from "shared/net";
import Dashboard from "views/Dashboard";
import Login from "views/pages/Login";
import IdleTimer from 'react-idle-timer'
import { baseURL } from "shared/net";

var ps;

class Admin extends React.Component {
  unlistenHistory
  verifyTokenInterval
  ctx = {loaded: false, _token: ''}

  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "black",
      activeColor: "info",
      sidebarMini: false,
      notice: null,
      confirmCallback: null,
      confirmMessage: null,
      confirmTitle: null,
      confirmDisplay: false,
      routes: [],
    };
    
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)

    // cache routes to avoid rerendering all pages when globalContext is in use
    this.renderedRoutes = this.getRoutes(routes);
  }

  handleOnAction(event) {
    // console.log('user did something', event)
  }

  handleOnActive(event) {
    console.log('user is active', event)
    console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  handleOnIdle(event) {
    console.log('user is idle', event)
    console.log('last active', this.idleTimer.getLastActiveTime())
    this.verifyToken()
  }

  async componentDidMount() {
    console.log('Admin componentDidMount')

    const container = document.querySelector('.sidebar-wrapper');
    // console.log('container ps!', contentContainer, this);
    if (container) {
      this.perfectScrollbar = new PerfectScrollbar(container, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    // https://trello.com/c/lVneBsin
    const contentContainer = document.querySelector('#root > .wrapper');
    // console.log('content ps!', contentContainer, this);
    // if (contentContainer) {
    //   this.contentScrollbar = new PerfectScrollbar(contentContainer, {
    //     suppressScrollX: true,
    //     suppressScrollY: false,
    //   });
    // }
    // contentContainer.addEventListener('ps-scroll-y', (e) => {
    //   console.log('scrolly', e);
    // });
    // container.addEventListener('ps-scroll-y', (e) => {
    //   console.log('sidebar scrolly', e);
    // });

    let token = localStorage.getItem("_token")
    this.ctx.loaded = token ? true : false
    this.ctx._token = token

    await this.populateRoutes()
    this.listenHistory()
    this.startVerifyTokenInterval()
  }

  componentWillUnmount() {
    if (this.perfectScrollbar) {
      this.perfectScrollbar.destroy();
    }
    if (this.contentScrollbar) {
      this.contentScrollbar.destroy();
    }
    if (this.unlistenHistory) {
      this.unlistenHistory()
    }
    if (this.verifyTokenInterval) {
      this.stopVerifyTokenInterval()
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      const contentContainer = document.querySelector('#root > .wrapper');

      if(contentContainer) {
        contentContainer.scrollTop = 0;
      }
      // this.refs.mainPanel.scrollTop = 0;
    }
  }

  startVerifyTokenInterval() {
    console.log('Admin startVerifyTokenInterval')
    if (!this.ctx.loaded) return
    if (this.verifyTokenInterval) return
    this.verifyTokenInterval = setInterval(() => {
      const currentPath = this.props.location.pathname
      if (
        [
          '/admin/logout', 
          '/auth/login'
        ].includes(currentPath)
      ) {
        // Do nothing
      } else {
        this.verifyToken()
      }
    }, 1000 * 60);
  }

  stopVerifyTokenInterval() {
    console.log('Admin stopVerifyTokenInterval')
    if (!this.verifyTokenInterval) return
    clearInterval(this.verifyTokenInterval)
    this.verifyTokenInterval = null
  }

  listenHistory() {
    this.unlistenHistory = this.props.history.listen((location, action) => {
      console.log('Admin componentDidMount location', location)
      console.log('Admin componentDidMount action', action)
      console.log('Admin componentDidMount ctx', this.ctx)
      if (
        ![
          '/admin/logout', 
          '/admin/dashboard', 
          '/auth/login'
        ].includes(location.pathname) &&
        this.ctx.loaded
      ) {
        this.refreshToken()
      }
    })
  }

  async populateRoutes() {
    if (!this.ctx.loaded) return
    let access = []
    let allowed = {}
    await fetchAxios(this.ctx, { url: '/v1/_utils/accessright/allowed' }).then((res) => {
      access = res.data.access
      allowed = res.data.allowed
    }).catch((err) => {
      console.error(`err`, err)
    })
    let allowedRoutes = [{
      path: "/dashboard",
      name: "Dashboard",
      icon: "nc-icon nc-bank",
      component: Dashboard,
      layout: "/admin",
      menu: false
    }]
    let allowedRoute = {}
    if (allowed.masterData) {
      allowedRoute = {
        collapse: true,
        name: "Master Data",
        icon: "nc-icon nc-book-bookmark",
        state: "masterDataCollapse",
        views: []
      }
    }
    if (allowed.masterData && allowed.masterData.area) {
      if (allowed.masterData.area.read) allowedRoute.views.push({
        path: "/areas",
        exact: true,
        name: "Area",
        mini: "A",
        component: React.lazy(() => import('pages/Areas/Index')),
        layout: "/admin",
      })
    }
    if (allowed.masterData && allowed.masterData.position) {
      if (allowed.masterData.position.read) allowedRoute.views.push({
        path: "/positions",
        exact: true,
        name: "Position",
        mini: "T",
        component: React.lazy(() => import('pages/Positions/Index')),
        layout: "/admin",
      })
    }
    if (allowed.masterData && allowed.masterData.skill) {
      if (allowed.masterData.skill.read) allowedRoute.views.push({
        path: "/skillsets",
        name: "Skill Set Setup",
        exact: true,
        mini: "SS",
        component: React.lazy(() => import('pages/SkillSets/Index')),
        layout: "/admin",
      })
    }
    if (allowed.masterData && allowed.masterData.skillGroup) {
      if (allowed.masterData.skillGroup.read) allowedRoute.views.push({
        path: "/skillgroups",
        name: "Skill Set Group Setup",
        exact: true,
        mini: "SG",
        component: React.lazy(() => import('pages/SkillGroups/Index')),
        layout: "/admin",
      })
    }
    if (allowed.masterData && allowed.masterData.refresherCourse) {
      if (allowed.masterData.refresherCourse) allowedRoute.views.push({
        path: "/refresher",
        name: "Refresher Training",
        exact: true,
        mini: "R",
        component: React.lazy(() => import('pages/Refresher/Index')),
        layout: "/admin",
      })
    }
    if (allowed.masterData && allowed.masterData.shift) {
      if (allowed.masterData.shift.read) allowedRoute.views.push({
        path: "/shifts",
        name: "Shift",
        exact: true,
        mini: "S",
        component: React.lazy(() => import('pages/Shifts/Index')),
        layout: "/admin",
      })
    }
    if (allowed.masterData && allowed.masterData.salaryRange) {
      if (allowed.masterData.salaryRange.read) allowedRoute.views.push({
        path: "/salaryranges",
        name: "Salary Range",
        exact: true,
        mini: "SR",
        component: React.lazy(() => import('pages/SalaryRanges/Index')),
        layout: "/admin",
      })
    }
    if (allowed.masterData && allowed.masterData.publicHoliday) {
      if (allowed.masterData.publicHoliday.read) allowedRoute.views.push({
        path: "/publicholidays",
        name: "Public Holiday",
        exact: true,
        mini: "PH",
        component: React.lazy(() => import('pages/PublicHolidays/Index')),
        layout: "/admin",
      })
    }
    if (allowed.masterData && allowed.masterData.rating) {
      if (allowed.masterData.rating.read) allowedRoute.views.push({
        path: "/ratings",
        name: "Rating",
        exact: true,
        mini: "RT",
        component: React.lazy(() => import('pages/Ratings/Index')),
        layout: "/admin",
      })
    }
    if (allowed.masterData && allowed.masterData.remark) {
      if (allowed.masterData.remark.read) allowedRoute.views.push({
        path: "/remarks",
        name: "Remark",
        exact: true,
        mini: "R",
        component: React.lazy(() => import('pages/Remarks/Index')),
        layout: "/admin",
      })
    }
    if (allowed.masterData && allowed.masterData.emailContent) {
      if (allowed.masterData.emailContent.read) allowedRoute.views.push({
        path: "/emailcontents",
        name: "Email Content",
        exact: true,
        mini: "E",
        component: React.lazy(() => import('pages/EmailContent/Index')),
        layout: "/admin",
      })
    }
    allowedRoutes.push(allowedRoute)

    allowedRoute = {}
    if (allowed.transaction) {
      allowedRoute = {
        collapse: true,
        name: "Transactions",
        icon: "nc-icon nc-briefcase-24",
        state: "transactionsCollapse",
        views: []
      }
    }
    if (allowed.transaction && allowed.transaction.partTimerApplication) {
      if (allowed.transaction.partTimerApplication.read) allowedRoute.views.push({
        path: "/transaction",
        name: "Part-Timer Applications",
        exact: true,
        mini: "CA",
        component: React.lazy(() => import('pages/Transaction/Index')),
        layout: "/admin",
      })
    }
    if (allowed.transaction && allowed.transaction.event) {
      if (allowed.transaction.event.read) allowedRoute.views.push({
        path: "/events",
        exact: true,
        name: "Job Event Calendar",
        mini: "T",
        component: React.lazy(() => import('pages/JobEventCalendar/Index')),
        layout: "/admin",
      })
    }
    if (allowed.transaction && allowed.transaction.block) {
      if (allowed.transaction.block.read) allowedRoute.views.push({
        path: "/BlockEmployee",
        name: "Block/Unblock",
        exact: true,
        mini: "BE",
        component: React.lazy(() => import('pages/BlockEmployee/Index')),
        layout: "/admin",
      })
    }
    if (allowed.transaction && allowed.transaction.profileSearch) {
      if (allowed.transaction.profileSearch.read) allowedRoute.views.push({
        path: "/profilesearch",
        exact: true,
        name: "Profile Search",
        mini: "T",
        component: React.lazy(() => import('pages/ProfileSearch/Index')),
        layout: "/admin",
      })
    }
    allowedRoutes.push(allowedRoute)

    allowedRoute = {}
    if (allowed.report) {
      allowedRoute = {
        collapse: true,
        name: "Reports",
        icon: "nc-icon nc-single-copy-04",
        state: "reportsCollapse",
        views: []
      }
    }
    if (allowed.report && allowed.report.partTimerListing) {
      if (allowed.report.partTimerListing.read) allowedRoute.views.push({
        path: "/part-timer-listing",
        name: "Part-Timer Listing",
        exact: true,
        mini: "PTL",
        component: React.lazy(() => import('pages/PartTimerListing/Index')),
        layout: "/admin",
      })
    }
    if (allowed.report && allowed.report.approvedPartTimer) {
      if (allowed.report.approvedPartTimer.read) allowedRoute.views.push({
        path: "/approved-part-timer",
        name: "Approved Part-Timer",
        exact: true,
        mini: "APT",
        component: React.lazy(() => import('pages/ApprovedPartTimer/Index')),
        layout: "/admin",
      })
    }
    if (allowed.report && allowed.report.approvedPartTimerWorkDate) {
      if (allowed.report.approvedPartTimerWorkDate.read) allowedRoute.views.push({
        path: "/aapproved-part-timer-work-date",
        name: "Approved Part-Timer (With Work Date)",
        exact: true,
        mini: "APTW",
        component: React.lazy(() => import('pages/ApprovedPartTimerByWorkDate/Index')),
        layout: "/admin",
      })
    }
    if (allowed.report && allowed.report.summaryPartTimeHeadcount) {
      if (allowed.report.summaryPartTimeHeadcount.read) allowedRoute.views.push({
        path: "/summary-part-time-headcount",
        name: "Summary of Part-Timer",
        exact: true,
        mini: "SPTH",
        component: React.lazy(() => import('pages/SummaryOfPartTimeHeadCount/Index')),
        layout: "/admin",
      })
    }
    if (allowed.report && allowed.report.partTimeRegistrationListing) {
      if (allowed.report.partTimeRegistrationListing.read) allowedRoute.views.push({
        path: "/part-time-registration-listing",
        name: "Part-Timer Registration",
        exact: true,
        mini: "PTRL",
        component: React.lazy(() => import('pages/PartTimeRegistrationListing/Index')),
        layout: "/admin",
      })
    }
    if (allowed.report && allowed.report.detailPartTimerListing) {
      if (allowed.report.detailPartTimerListing.read) allowedRoute.views.push({
        path: "/detail-part-timer-listing",
        name: "Part-Timer Rating (Detail Listing)",
        exact: true,
        mini: "DPTL",
        component: React.lazy(() => import('pages/DetailPartTimerListing/Index')),
        layout: "/admin",
      })
    }
    if (allowed.report && allowed.report.summaryPartTimer) {
      if (allowed.report.summaryPartTimer.read) allowedRoute.views.push({
        path: "/summary-part-timer",
        name: "Part-Timer Rating (Summary Listing)",
        exact: true,
        mini: "SLPT",
        component: React.lazy(() => import('pages/SummaryListingOfPartTimersRateDepartment/Index')),
        layout: "/admin",
      })
    }
    allowedRoutes.push(allowedRoute)

    allowedRoute = {}
    if (allowed.accessControl) {
      allowedRoute = {
        collapse: true,
        name: "Access control",
        icon: "nc-icon nc-circle-10",
        state: "accessCollapse",
        views: []
      }
    }
    if (allowed.accessControl && allowed.accessControl.user) {
      if (allowed.accessControl.user.read) allowedRoute.views.push({
        path: "/user",
        name: "User",
        exact: true,
        mini: "U",
        component: React.lazy(() => import('pages/User/Index')),
        layout: "/admin",
      })
    }
    if (allowed.accessControl && allowed.accessControl.group) {
      if (allowed.accessControl.group.read) allowedRoute.views.push({
        path: "/group",
        name: "Group",
        exact: true,
        mini: "U",
        component: React.lazy(() => import('pages/Group/Index')),
        layout: "/admin",
      })
    }
    if (allowed.accessControl && allowed.accessControl.role) {
      if (allowed.accessControl.role.read) allowedRoute.views.push({
        path: "/role",
        name: "Role",
        exact: true,
        mini: "U",
        component: React.lazy(() => import('pages/Role/Index')),
        layout: "/admin",
      })
    }
    allowedRoutes.push(allowedRoute)

    allowedRoutes.push({
      name: "Help",
      icon: "fa fa-question-circle-o",
      opentab: `${baseURL}/public/RWG Recruiters Mobile App & Web User Guide.pdf`
    })
    allowedRoutes.push({
      path: "/login",
      name: "Login",
      mini: "L",
      component: Login,
      layout: "/auth",
      menu: false
    })
    allowedRoutes.push({
      path: "/logout",
      name: "Logout",
      icon: "nc-icon nc-user-run",
      component: React.lazy(() => import('pages/SignOut')),
      layout: "/admin",
    })

    if (access && access.length > 0) {
      this.setState({routes: allowedRoutes})
    } else {
      this.setState({routes})
    }
  }

  refreshToken() {
    fetchAxios(
      this.ctx, 
      {url: '/v1/auth/expiry/refresh'}
    ).then(res => {
      // Do nothing
    }).catch(err => {
      this.stopVerifyTokenInterval()
      alert(err.message)
      this.props.history.push('/admin/logout')
    })
  }

  verifyToken() {
    fetchAxios(
      this.ctx, 
      {url: '/v1/auth/expiry'}
    ).then(res => {
      // Do nothing
    }).catch(err => {
      this.stopVerifyTokenInterval()
      alert(err.message)
      this.props.history.push('/admin/logout')
    })
  }

  getRoutes(routes) {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        const exact = prop.exact === true;
        return (
          <Route
            path={prop.layout + prop.path}
            component={withCRUD(prop.component)}
            key={key}
            exact={exact}
          />
        );
      } else {
        return null;
      }
    });
  };
  handleActiveClick(color) {
    this.setState({ activeColor: color });
  };
  handleBgClick(color) {
    this.setState({ backgroundColor: color });
  };
  handleMiniClick() {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  render() {
    const closeModal = () => {
      this.setState({ confirmDisplay: false })
    }
    return (
      <div className="wrapper">
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          timeout={1000 * 60 * 21}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <AuthContextProvider >
          <>
              <AuthContext.Consumer>
                {authCtx => {
                  if (authCtx.loaded) {
                    if (!authCtx._token) {
                      return <Redirect to="/auth/login"></Redirect>;
                    }

                    return (
                      <GlobalContext.Provider
                        value={{
                          props: this.props,
                          globalNotice: this.state.globalNotice,
                          setGlobalNotice: (val, callback = null) =>
                            this.setState({ globalNotice: val }, callback),
                          closeGlobalNotice: () =>
                            this.setState({ globalNotice: null }),
                          globalError: this.state.globalError,
                            setGlobalError: (val, callback = null) =>
                              this.setState({ globalError: val }, callback),
                            closeGlobalError: () =>
                              this.setState({ globalError: null }),
                          confirmDialog: (
                            msg,
                            callback,
                            title = "Confirm?"
                          ) => {
                            console.log("confirmDialog", msg);
                            this.setState({
                              confirmCallback: callback,
                              confirmMessage: msg,
                              confirmTitle: title,
                              confirmDisplay: true,
                            });
                          },
                        }}
                      >
                        <>
                          <Sidebar
                            {...this.props}
                            routes={this.state.routes}
                            authCtx={authCtx}
                            bgColor={this.state.backgroundColor}
                            activeColor={this.state.activeColor}
                          />
                          <div
                            className="main-panel"
                            // ref="mainPanel"
                          >
                            <AdminNavbar
                              {...this.props}
                              authCtx={authCtx}
                              handleMiniClick={(e) => this.handleMiniClick(e)}
                            />
                            <Suspense fallback={<div>Loading...</div>}>
                              {this.state.globalNotice && (
                                <Alert 
                                  color='success'
                                  closeAriaLabel="Close"
                                  className="globalNotice"
                                  toggle={(e) => {
                                    this.setState({ globalNotice: null });
                                  }}
                                >
                                  {this.state.globalNotice}
                                </Alert>
                              )}

                              {this.state.globalError && (
                                <Alert 
                                  color='danger'
                                  closeAriaLabel="Close"
                                  className="globalNotice"
                                  toggle={(e) => {
                                    this.setState({ globalError: null });
                                  }}
                                >
                                  {this.state.globalError}
                                </Alert>
                              )}

                              <Modal
                                isOpen={this.state.confirmDisplay}
                                toggle={closeModal}
                              >
                                <ModalHeader toggle={closeModal}>
                                  {this.state.confirmTitle}
                                </ModalHeader>
                                <ModalBody
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.confirmMessage,
                                  }}
                                />
                                <ModalFooter>
                                  <Button
                                    style={{ height: "40px", minWidth: "130px", fontSize: "14px" }}
                                    color="primary"
                                    onClick={(e) => {
                                      this.state.confirmCallback(e);
                                      this.setState({
                                        confirmDisplay: false,
                                      });
                                    }}
                                  >
                                    Yes
                                  </Button>
                                  <Button
                                    style={{ height: "40px", minWidth: "130px", fontSize: "14px" }}
                                    color="secondary"
                                    onClick={() =>
                                      this.setState({ confirmDisplay: false })
                                    }
                                  >
                                    No
                                  </Button>{" "}
                                  <span style={{ width: "1em" }}> </span>
                                </ModalFooter>
                              </Modal>
                              {!this.state.globalNotice && !this.state.globalError &&(
                                <div style={{ height: "57px" }} />
                              )}
                              <Switch>{this.renderedRoutes}</Switch>
                            </Suspense>
                            {
                              // we don't want the Footer to be rendered on full screen maps page
                              this.props.location.pathname.indexOf(
                                "full-screen-map"
                              ) !== -1 ? null : (
                                <Footer fluid />
                              )
                            }
                          </div>
                        </>
                      </GlobalContext.Provider>
                    );
                  }
                  return null;
                }}
              </AuthContext.Consumer>
            
            {/* <FixedPlugin
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          sidebarMini={this.state.sidebarMini}
          handleActiveClick={(color) => this.handleActiveClick(color)}
          handleBgClick={(color) => this.handleBgClick(color)}
          handleMiniClick={(color) => this.handleMiniClick(color)}
        /> */}
          </>
        </AuthContextProvider>
      </div >
    );
  }
}

export default Admin;
