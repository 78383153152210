import React from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import logo from "assets/img/rwgWhite.png";
import funmaker from "assets/img/bg/banner.png";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
} from "reactstrap";

class AuthNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
    };
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
  }
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse () {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };
  render() {
    return (
      <Navbar
        className={classnames("navbar-absolute fixed-top", this.state.color)}
        expand="lg"
      >
        <Container>
          {/* <div className="navbar-wrapper-auth">
            <NavbarBrand>
              <div className="row flex" style={{paddingRight: "20px", paddingLeft: "20px"}}>
            <img src={logo} className="center"/>
            </div>
            <div className="row flex">
            <img src={funmaker} className="center"/>
            </div>
            </NavbarBrand>
          </div> */}
          
          
        </Container>
      </Navbar>
    );
  }
}

export default AuthNavbar;
