/*eslint-disable*/
import React, { useEffect, useState } from "react";
import {
  useTable,
  useFilters,
  useAsyncDebounce,
  useSortBy,
  usePagination,
} from "react-table";
import classnames from "classnames";
// A great library for fuzzy filtering/sorting items
import matchSorter from "match-sorter";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";
import PropTypes from 'prop-types';

// reactstrap components
import {
  Container, Row, Col, FormGroup, Input, Button,
  InputGroup
} from "reactstrap";

// Define a default UI for filtering
function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;
  // console.log('callings etfilter', filterValue);
  return (
    <FormGroup>
      <Input
        placeholder={`Search...`}
        type="text"
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    </FormGroup>
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Our table component
function Table({ columns, data, pageSize, pageOffset, pageSizes, manualPagination,
  autoResetPage, onSetPageSize, onGotoPage, pageCount, sortBy, onSort, onDelete, checked,
  tableStyle, onCheckAll, toggleSelectAll }) {
  const [numberOfRows, setNumberOfRows] = React.useState({
    value: 10,
    label: "10 rows",
  });
  const [pageSelect, handlePageSelect] = React.useState({
    value: 0,
    label: "1",
  });
  const [checkAll, setCheckAll] = useState(false);
  const filterTypes = React.useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      width: 100,
      minWidth: 50,
      maxWidth: 100,
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  useEffect(() => {
    // set to actual reacttable
    setPageSize(pageSize);
  }, [pageSize]);

  useEffect(() => {
    gotoPage(pageOffset);
  }, [pageOffset]);

  const {
    sortBy: actualSortBy,
    filters,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    visibleColumns,
    nextPage,
    pageOptions,
    previousPage,
    setPageSize,
    gotoPage,
    onSortedChange,
  } = useTable(
    {
      columns,
      data,
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      initialState: { pageSize, pageIndex: pageOffset, sortBy },
      manualPagination,
      autoResetPage,
      pageCount,
      disableFilters: true,
      manualFilters: true,
      manualSortBy: true,
      // autoResetSortBy: true
    },
    useFilters,
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    const sorted = state.sortBy[0];
    if (!sorted) {
      return;
    }
    // react table bug that change sort data even the column is not sortable
    console.log('actualSortBy', sorted);
    const { id: n } = sorted;
    const sortableCols = columns.filter((col) => col.sortable !== false).map((col) => col.accessor);
    console.log('sortable', sortableCols);
    if (!sortableCols.includes(n)) {
      return;//not sortable
    }
    if (onSort) {
      onSort(state);
    }
  }, [state.sortBy]);

  // console.log('onSortedChange', onSortedChange);
  // console.log('headerGroups', headerGroups[0].headers);
  const selectedPageSize = { value: pageSize, label: `${pageSize} rows` };
  const computedPage = Math.ceil(pageOffset / pageSize) + 1;
  const selectedPage = { value: computedPage, label: `${computedPage}` };


  const canPreviousPage = computedPage > 1 && pageCount > 1;
  const canNextPage = computedPage < pageCount;
  // We don't want to render all of the rows for this example, so cap
  // it for this use case
  // const firstPageRows = rows.slice(0, 10);
  const getDefaultColumnStyle = (index) => {
    // console.log('getDefaultColumnStyle', index);
    if (typeof index == 'string') {
      const length = Math.round(Math.max(index.length, 5) * 0.8);
      return { width: `${length}em`, textAlign: 'left', alignItems: 'left'};
    }
    const name = columns[index].Header;
    // console.log('name', name);
    const length = Math.round(Math.max(name.length, 8) * 0.8);
    return { width: `${length + 3}em`, textAlign: 'left', alignItems: 'left'};
  }
  let pageSelectData = Array.apply(
    null,
    Array(pageOptions.length)
  ).map(function () { });
  return (
    <div className="ReactTable -striped -highlight primary-pagination" >
      <div className="card" style={{ padding: "16px", overflowX: "auto" }}>
        <table {...getTableProps()} className="table table-striped" style={tableStyle}>
        <thead >
          {toggleSelectAll && <label style={{whiteSpace: 'nowrap'}} htmlFor="selectAll" ><input id="selectAll" type="checkbox" onChange={toggleSelectAll} />
            {' '}
            Select all
          </label>}
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, key) => (
                <th
                  {...column.getHeaderProps({... column.getSortByToggleProps(), ...({ style: column.style || getDefaultColumnStyle(key) }) })}
                  
                >
                  <div >
                    {column.render("Header")}
                  </div>
                  {/* Render the columns filter UI */}
                  <div>
                    {headerGroup.headers.length - 1 === key
                      ? null
                      : column.filterable
                        ? column.render("Filter")
                        : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                
              >
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()} style={columns[i].style || getDefaultColumnStyle(i) } >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
        
      </table>
      {data?.length < 1 && <div className="row" style={{alignSelf: 'center'}}><span>No record found</span></div>}
      {(canNextPage || canPreviousPage) && <div className="pagination-bottom">
        <div className="-pagination">
          <div className="" style={{ width: '2em' }}>
            {onDelete && <Input type="checkbox" defaultChecked={checkAll} onChange={() => { const c = !checkAll; setCheckAll(c); onCheckAll(c) }} className="ml-2" />}
          </div>
          <div className="ml-1 mr-2">
            {onDelete && Object.keys(checked).length > 0 &&
              <Button type="button" onClick={onDelete} style={{ margin: 0, flex: '1 1' }}><i className="fa fa-trash-alt"></i>Delete</Button>}
          </div>

          <div className="-first" style={{width: '50px', height: '50px', marginRight: '5px'}}>
            <button
              type="button"
              onClick={() => {
                document.documentElement.scrollTop = 0;
                document.scrollingElement.scrollTop = 0;
                const contentContainer = document.querySelector('#root > .wrapper');

                if(contentContainer) {
                  contentContainer.scrollTop = 0;
                }
                if (computedPage > 1 && onGotoPage) {
                  onGotoPage(1);
                }
              }}
              disabled={!canPreviousPage || computedPage == 1}
              className="-btn"
            >
            {' '}
            &lt;&lt;
            {' '}
              </button>
          </div>

          {' '}

          <div className="-previous">
            <button
              type="button"
              onClick={() => {
                document.documentElement.scrollTop = 0;
                document.scrollingElement.scrollTop = 0;
                const contentContainer = document.querySelector('#root > .wrapper');

                if(contentContainer) {
                  contentContainer.scrollTop = 0;
                }
                if (computedPage > 1 && onGotoPage) {
                  onGotoPage(computedPage - 1);
                }
              }}
              disabled={!canPreviousPage}
              className="-btn"
            >
            &lt;
              </button>
          </div>

          <div className="-center">
            <Container>
                  <Select 
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="pageSelect"
                    onChange={(value) => {
                      document.documentElement.scrollTop = 0;
                      document.scrollingElement.scrollTop = 0;
                      const contentContainer = document.querySelector('#root > .wrapper');

                      if(contentContainer) {
                        contentContainer.scrollTop = 0;
                      }
                      if (onGotoPage) {
                        onGotoPage(value.value);
                      }
                    }}
                    value={selectedPage}
                    options={pageSelectData.map((prop, key) => {
                      return {
                        value: key + 1,
                        label: "" + (key + 1),
                      };
                    })}
                    placeholder="Choose Page"
                  />
            </Container>
          </div>
          {' '}
          <div className="-next">
            <button
              type="button"
              onClick={() => {
                document.documentElement.scrollTop = 0;
                document.scrollingElement.scrollTop = 0;
                const contentContainer = document.querySelector('#root > .wrapper');

                if(contentContainer) {
                  contentContainer.scrollTop = 0;
                }
                if (computedPage < pageCount && onGotoPage) {
                  onGotoPage(computedPage + 1);
                }
              }}
              disabled={!canNextPage}
              className="-btn"
            >
            &gt;
              </button>
          </div>
          {' '}
          <div className="-last" style={{width: '50px', height: '50px', marginLeft: '5px'}}>
            <button
              type="button"
              onClick={() => {
                document.documentElement.scrollTop = 0;
                document.scrollingElement.scrollTop = 0;
                const contentContainer = document.querySelector('#root > .wrapper');

                if(contentContainer) {
                  contentContainer.scrollTop = 0;
                }
                if (computedPage < pageCount && onGotoPage) {
                  onGotoPage(pageCount);
                }
              }}
              disabled={!canNextPage || computedPage == pageCount}
              className="-btn"
            >
            {' '}
            &gt;&gt;
            {' '}
            </button>
          </div>

        </div>

        

      </div>}
      </div>
      
    </div >
  );
}

// Define a custom filter filter function!
function filterGreaterThan(rows, id, filterValue) {
  return rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

Table.defaultProps = {
  pageSize: 20,
  pageOffset: 0,//offset
  pageCount: 0,
  manualPagination: true,
  pageSizes: [5, 10, 20, 25, 50, 100],
  autoResetPage: true,
  onSetPageSize: null,
  onGotoPage: null,
  onSort: null,
  onDelete: null,
  onCheckAll: null,
  sortBy: {},
  checked: {},
  tableStyle: {}
}

Table.propTypes = {
  pageSize: PropTypes.number,
  pageOffset: PropTypes.number,
  pageCount: PropTypes.number,
  manualPagination: PropTypes.bool,
  pageSizes: PropTypes.arrayOf(PropTypes.number),
  autoResetPage: PropTypes.bool,
  onSetPageSize: PropTypes.func,

  onGotoPage: PropTypes.func,
  onSort: PropTypes.func,
  sortBy: PropTypes.arrayOf(PropTypes.shape({})),
  checked: PropTypes.shape({}),
  onDelete: PropTypes.func,
  onCheckAll: PropTypes.func,
  tableStyle: PropTypes.shape({}),
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val) => typeof val !== "number";

export default Table;
