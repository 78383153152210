import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import logo from "assets/img/rwg.png";
import funmaker from "assets/img/bg/banner.png";

class Dashboard extends React.Component {
  render() {
    return (
      <>
        <div className="content">
          
        </div>
      </>
    );
  }
}

export default Dashboard;
